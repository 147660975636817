<style lang="scss" scoped>

  .photo-container {
    flex-direction: column;
    @media only screen and (min-width: 960px) {
      gap: 3.75rem;
      flex-direction: row;
    }

    .photo {
      aspect-ratio: 16 / 9;
      object-fit: cover;
      @media only screen and (min-width: 960px) {
        width: 40%;
      }
    }

    .piggy {
      background: rgba(106, 149, 63, 0.15);
      @media only screen and (max-width: 960px) {
        font-size: 0.625rem;
      }
    }

    h1 span {
      color: $brandPurple;
      white-space: nowrap;
      background-position: bottom -2px left 0;
    }

    h4 {
      text-wrap: balance;
      max-width: 30rem;
      @media only screen and (max-width: 960px) {
        font-size: 0.875rem;
      }
    }
  }
</style>

<template>
  <div class="d-flex flex-column h-100">
    <v-container class="h-100 d-flex flex-column">
      <div class="d-flex align-center flex-column mb-8 mb-md-16 flex-grow-1">
        <div class="d-flex w-100 photo-container">
          <img class="photo rounded-lg" src="~/assets/images/photos/blue-house-white-trim.jpg" alt="Blue house with white trim">
          <div class="flex-1 d-flex flex-column align-center">
            <div class="mb-6 rounded-xl mt-10 px-3 py-2 text-center piggy">
              <v-icon icon="mdi-piggy-bank-outline" />&nbsp;&nbsp;Coming soon
            </div>
            <h1 class="mb-6 text-center">
              Mortgages are <span :style="{ backgroundImage: 'url(' + swoop + ')' }">improving!</span>
            </h1>
            <h4 class="mb-8 mb-md-16 text-center">
              The wait is almost over. Come see us November 15, 2024.
            </h4>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script setup>
import swoop from '@/assets/images/swoop.svg';

useHead({
  title: 'Mortgages',
});

</script>
